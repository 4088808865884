<template>
<div class="formulaire1 text-left">
    <div class="question type_checkbox">
        <h4>{{this.$store.state.prenom}},what is the capital city of Australia ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="Canberra" v-model="question1">
        <label class="form-check-label" for="defaultCheck1">
           Canberra.
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="Sydney" v-model="question1">
        <label class="form-check-label" for="defaultCheck2">
            Sydney.
        </label>    
         </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="Melbourne" v-model="question1">
        <label class="form-check-label" for="defaultCheck3">   
        </label>
            Melbourne.
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success1">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore !
            </div>
        </div>
       Ta réponse : {{question1}} 
    </div>
    <div class="question type_radio">
        <h4>What is the name given to the first inhabitants of Australia ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="the Aborigines" v-model="question2">
        <label class="form-check-label" for="exampleRadios1">
            the Aborigines.
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="the Australians" v-model="question2">
        <label class="form-check-label" for="exampleRadios2">
             the Australians.
        </label>
        </div>
        <div class="form-check disabled">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="the Natives" v-model="question2">
        <label class="form-check-label" for="exampleRadios3">
             the Natives.
        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success2">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question2}}
    </div>
        <div class="question type_select">
        <h4>Which of these places is not in Austalia  ?</h4>
          <div class="form-group">
            <select class="form-control" id="exampleFormControlSelect1"  style="width:200px" v-model="question3">
            <option value="1">Harbour Bridge</option>
            <option value="2">Lake Eyre</option>
            <option value="3">Hobart</option>
            </select>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success3">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question3}}
    </div>
            <div class="question type_checkbox">
        <h4>What is the name of the large sandstone rock formation in the centre of the country ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="the Blue Mountains" v-model="question4">
        <label class="form-check-label" for="defaultCheck1">
            the Blue Mountains.
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="Ayers Rock" v-model="question4">
        <label class="form-check-label" for="defaultCheck2">
           Ayers Rock.
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="Uluru" v-model="question4">
        <label class="form-check-label" for="defaultCheck3">
           Uluru.
        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success4">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
       Ta réponse :  {{ question4 }}
    </div>
        <div class="question type_radio">
        <h4>Sydney is ... city in Australia.</h4>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios2" id="exampleRadios1" value="the biggest" v-model="question5">
        <label class="form-check-label" for="exampleRadios1">
            the biggest.
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios2" id="exampleRadios2" value="yhe bigest" v-model="question5">
        <label class="form-check-label" for="exampleRadios2">
            the bigest.

        </label>
        </div>
        <div class="form-check disabled">
        <input class="form-check-input" type="radio" name="exampleRadios2" id="exampleRadios3" value="the most big" v-model="question5">
        <label class="form-check-label" for="exampleRadios3">
            the most big.

        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success5">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question5}}
    </div>

            <div class="question type_radio">
        <h4>Perth is ... city in the world.</h4>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios3" id="exampleRadios1" value="the isolated" v-model="question6">
        <label class="form-check-label" for="exampleRadios1">
            the isolated
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios3" id="exampleRadios2" value="the most isolated" v-model="question6">
        <label class="form-check-label" for="exampleRadios2">
            the most isolated

        </label>
        </div>
        <div class="form-check disabled">
        <input class="form-check-input" type="radio" name="exampleRadios3" id="exampleRadios3" value="the more isolated" v-model="question6">
        <label class="form-check-label" for="exampleRadios3">
            the more isolated

        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success6">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question6}}
    </div>

                <div class="question type_checkbox">
        <h4>Which animals hop and have puches ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="dingoes" v-model="question7">
        <label class="form-check-label" for="defaultCheck1">
           dingoes
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="kangaroos" v-model="question7">
        <label class="form-check-label" for="defaultCheck2">
           kangaroos
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="koalas" v-model="question7">
        <label class="form-check-label" for="defaultCheck3">
           koalas
        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success7">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
       Ta réponse :  {{ question7 }}
    </div>


    <div class="question type_select">
        <h4>What is the name of the Australian animal that has big hairy ears and claws for climbing ?</h4>
          <div class="form-group">
            <select class="form-control" id="exampleFormControlSelect1"  style="width:200px" v-model="question8">
            <option value="dingo">Dingo</option>
            <option value="emu">Emu</option>
            <option value="koala">Koala</option>
            </select>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success8">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question8}}
    </div>

        <div class="question type_select">
        <h4>Give the name of the Ausrtalian river mammal with a beak that can swim and lay eggs ?</h4>
          <div class="form-group">
            <select class="form-control" id="exampleFormControlSelect1"  style="width:200px" v-model="question9">
            <option value="platypus">platypus</option>
            <option value="seal">seal</option>
            <option value="kiwi">kiwi</option>
            </select>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success9">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question9}}
         <br /> <br />
        <div class="action" v-if="!isOk">
            <button @click="verify2" class="btn btn-primary">Vérifier mes réponses</button>
        </div>
        <div class="action" v-else>
            <button @click="redirect" class="btn btn-primary">Merci !, voir mon score</button>
        </div>
    </div>
</div>
</template>
<script>
export default{
  data () {
    return {
      score:0,
      isOk : false,
      success : false,
      success1 : false,
      success2 : false,
      success3 : false,
      success4 : false,
      success5 : false,
      success6 : false,
      success7 : false,
      success8 : false,
      success9 : false,
      question1 : [],
      question2 : null,
      question3 : null,
      question4 : [],
      question5 : null,
      question6 : null,
      question7 : [],
      question8 : null,
      question9: null,
      answers : [
          {reponse : ["Canberra"]},
          {reponse : 'the Aborigines'},
          {reponse : "3"},
          {reponse : ["Ayers Rock", "Uluru"]},
          {reponse : "the biggest"},
          {reponse : "the most isolated"},
          {reponse : ["kangaroos"]},
          {reponse : "koala"},
          {reponse : "platypus"},
      ]
    }
  },
  methods: {
    redirect () {
        this.$router.push({name : 'Home'})
    },
    verify2() {
        for(var i=0;i<9;i++) {
            let question = eval('this.question'+(i+1));
            this.success = eval('this.success'+(i+1));
            if(question === this.answers[i].reponse) {
                alert(i);
                switch(i) {
                    case 0 :  this.success1 = true;
                    break;
                    case 1 :  this.success2 = true;
                    break;
                    
                }
                this.score = (this.score + 5)
            } else {
                switch(i) {
                    case 0 :  this.success1 = false;
                    break;
                    case 1 :  this.success2 = false;
                    break;
                    
                }
            }
            this.$store.commit('ajout_score', this.score)
            this.isOk = true
        }

    },
    verify () {
      if(this.question1.sort().toString() === this.answers[0].reponse.sort().toString()) {
         this.success1 = true
         this.score = (this.score + 5)
      } else {
          this.success1 = false
      }

     if(this.question2 === this.answers[1].reponse) {
         this.success2 = true
         this.score = (this.score + 5)
      } else {
          this.success2 = false
      }

      if(this.question3 === this.answers[2].reponse) {
         this.success3 = true
         this.score = (this.score + 5)
      } else {
          this.success3 = false
      }

    if(this.question4.sort().toString() === this.answers[3].reponse.sort().toString()) {
         this.success4 = true
         this.score = (this.score + 5)
      } else {
          this.success4 = false
      }

    if(this.question5 === this.answers[4].reponse) {
         this.success5 = true
         this.score = (this.score + 5)
    } else {
          this.success5 = false
    }

    if(this.question6 === this.answers[5].reponse) {
         this.success6 = true
         this.score = (this.score + 5)
    } else {
          this.success6 = false
    }

    if(this.question7.sort().toString() === this.answers[6].reponse.sort().toString()) {
         this.success7 = true
         this.score = (this.score + 5)
    } else {
          this.success7 = false
    }

    if(this.question8 === this.answers[7].reponse) {
         this.success8 = true
         this.score = (this.score + 5)
      } else {
          this.success8 = false
      }

          if(this.question9 === this.answers[8].reponse) {
         this.success9 = true
         this.score = (this.score + 5)
      } else {
          this.success9 = false
      }
    
    this.$store.commit('ajout_score', this.score)
    this.isOk = true
    }
  }

}
</script>

<style>

.question {
    margin: 0 0 40px 0;
}
</style>