<template>
<div class="formulaire1 text-left">
    <div class="question type_checkbox">
        <h4>{{this.$store.state.prenom}}, 
       le nomre 385 est divisible par ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="radio" value="5" v-model="question1">
        <label class="form-check-label" for="defaultCheck1">
           5
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" value="9" v-model="question1">
        <label class="form-check-label" for="defaultCheck2">
            9
            </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" value="10" v-model="question1">
        <label class="form-check-label" for="defaultCheck2">
            10
        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success1">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore !
            </div>
        </div>
       Ta réponse :   {{question1}}
    </div>
    <div class="question type_radio">
        <h4> Quel est le nombre premier ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="2" v-model="question2">
        <label class="form-check-label" for="exampleRadios1">
            2
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="54 " v-model="question2">
        <label class="form-check-label" for="exampleRadios2">
                      54

        </label>
        </div>
        <div class="form-check disabled">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="10" v-model="question2">
        <label class="form-check-label" for="exampleRadios3">
                        10

        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success2">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question2}}
    </div>
        <div class="question type_select">
        <h4> A un jeu vidéo, Marc gagne 3 fois 5 points et perd 7 fois 2 points. Son score est …?</h4>
          <div class="form-group">
            <select class="form-control" id="exampleFormControlSelect1"  style="width:200px" v-model="question3">
            <option value="29 points">29 points</option>
            <option value="-1 point">-1 point</option>
            <option value="1 point">1 point.</option>
            </select>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success3">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question3}}
    </div>
            <div class="question type_checkbox">
        <h4>L’expression -5+3×(-2)+8 est égale à ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="7" v-model="question4">
        <label class="form-check-label" for="defaultCheck1">
           Syntaxe1  : &lt; 7 &gt;
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="-3" v-model="question4">
        <label class="form-check-label" for="defaultCheck2">
           Syntaxe2  : &lt; -3 &gt;
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="12" v-model="question4">
        <label class="form-check-label" for="defaultCheck3">
           Syntaxe3  : &lt; 12 &gt;
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="6632444" v-model="question4">
        <label class="form-check-label" for="defaultCheck4">
           Syntaxe4  : &lt; 6632444 &gt;
        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success4">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
       Ta réponse : {{ question4 }}
    </div>
        <div class="question type_radio">
        <h4>A=3x-5 Pour x=2, …</h4>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios2" id="exampleRadios1" value="Cassoulet Saucisses Salées" v-model="question5">
        <label class="form-check-label" for="exampleRadios1">
            Cassoulet Saucisses Salées
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios2" id="exampleRadios2" value="0" v-model="question5">
        <label class="form-check-label" for="exampleRadios2">
            0

        </label>
        </div>
        <div class="form-check disabled">
        <input class="form-check-input" type="radio" name="exampleRadios2" id="exampleRadios3" value="1" v-model="question5">
        <label class="form-check-label" for="exampleRadios3">
           1

        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success5">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question5}}
    </div>

            <div class="question type_radio">
        <h4>Un bateau, situé à 4 km du port, se déplace à la vitesse de 12 km/h. Son arrivée au port est prévue dans …</h4>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios3" id="exampleRadios1" value="3" v-model="question6">
        <label class="form-check-label" for="exampleRadios1">
            3 min
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios3" id="exampleRadios2" value="20 min" v-model="question6">
        <label class="form-check-label" for="exampleRadios2">
           20 min

        </label>
        </div>
        <div class="form-check disabled">
        <input class="form-check-input" type="radio" name="exampleRadios3" id="exampleRadios3" value="33min" v-model="question6">
        <label class="form-check-label" for="exampleRadios3">
            33 min

        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success6">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question6}}
    </div>

                <div class="question type_checkbox">
        <h4>Sur la Terre, l’équateur est …</h4>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="un mérindien" v-model="question7">
        <label class="form-check-label" for="defaultCheck1">
           un mérindien
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="un parallèle" v-model="question7">
        <label class="form-check-label" for="defaultCheck2">
          un parrallèle
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="un demi cercle" v-model="question7">
        <label class="form-check-label" for="defaultCheck3">
           un demi cercle &gt;...&lt;/p&gt;
        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success7">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
       Ta réponse : {{ question7 }}
    </div>

    <div class="question type_select">
        <h4>Sur la Terre, tous les points situés sur le méridien de Greenwich ont pour …</h4>
          <div class="form-group">
            <select class="form-control" id="exampleFormControlSelect1" v-model="question8" style="width:200px">
            <option value="1">Latitude 0°</option>
           <option value="2">longitude 0°</option>
           <option value="3">Latitude 90°</option>
            <option value="4">Aucune réponse n'est valable.</option>
            </select>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success8">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question8}}
    </div>

            <br /> <br />
        <div class="action" v-if="!isOk">
            <button @click="verify" class="btn btn-primary">Vérifier mes réponses</button>
        </div>
        <div class="action" v-else>
            <button @click="redirect" class="btn btn-primary">Merci !, voir mon score</button>
        </div>
</div>
</template>
<script>
export default{
  data () {
    return {
      score:0,
      isOk : false,
      success1 : false,
      success2 : false,
      success3 : false,
      success4 : false,
      success5 : false,
      success6 : false,
      success7 : false,
      success8 : false,
      question1 :null,
      question2 : null,
      question3 : null,
      question4 : [],
      question5 : null,
      question6 : null,
      question7 : [],
      question8 : null,
      answers : [
          {reponse : '5'},
          {reponse : '2'},
          {reponse : "1 point"},
          {reponse : ["-3"]},
          {reponse : "1"},
          {reponse : "20 min"},
          {reponse : ["un parallèle"]},
          {reponse : "2"},
      ]
    }
  },
  methods: {
    redirect () {
        this.$router.push({name : 'Home'})
    },
    verify () {
      if(this.question1 === this.answers[0].reponse) {
         this.success1 = true
         this.score = (this.score + 5)
      } else {
          this.success1 = false
      }

     if(this.question2 === this.answers[1].reponse) {
         this.success2 = true
         this.score = (this.score + 5)
      } else {
          this.success2 = false
      }

      if(this.question3 === this.answers[2].reponse) {
         this.success3 = true
         this.score = (this.score + 5)
      } else {
          this.success3 = false
      }

    if(this.question4.sort().toString() === this.answers[3].reponse.sort().toString()) {
         this.success4 = true
         this.score = (this.score + 5)
      } else {
          this.success4 = false
      }

    if(this.question5 === this.answers[4].reponse) {
         this.success5 = true
         this.score = (this.score + 5)
    } else {
          this.success5 = false
    }

    if(this.question6 === this.answers[5].reponse) {
         this.success6 = true
         this.score = (this.score + 5)
    } else {
          this.success6 = false
    }

    if(this.question7.sort().toString() === this.answers[6].reponse.sort().toString()) {
         this.success7 = true
         this.score = (this.score + 5)
    } else {
          this.success7 = false
    }

    if(this.question8 === this.answers[7].reponse) {
         this.success8 = true
         this.score = (this.score + 5)
      } else {
          this.success8 = false
      }
    
    this.$store.commit('ajout_score', this.score)
    this.isOk = true
    }
  }

}
</script>

<style>

.question {
    margin: 0 0 40px 0;
}
</style>