<template>
<div class="formulaire1 text-left">
    <div class="question type_checkbox">
        <h4>{{this.$store.state.prenom}}, quel le rôle du Html ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="Structurer du contenu" v-model="question1">
        <label class="form-check-label" for="defaultCheck1">
           Structurer du contenu.
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="Donner du style à une page" v-model="question1">
        <label class="form-check-label" for="defaultCheck2">
            Donner du style à une page.
        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success1">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore !
            </div>
        </div>
       Ta réponse :  {{ question1 }}
    </div>
    <div class="question type_radio">
        <h4>A ton avis, il existe combien de balise titre différente ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="2 balises" v-model="question2">
        <label class="form-check-label" for="exampleRadios1">
            2
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="4 balises" v-model="question2">
        <label class="form-check-label" for="exampleRadios2">
                       4

        </label>
        </div>
        <div class="form-check disabled">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="6 balises" v-model="question2">
        <label class="form-check-label" for="exampleRadios3">
                        6

        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success2">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question2}}
    </div>
        <div class="question type_select">
        <h4>Cherche l'erreur ?</h4>
          <div class="form-group">
            <select class="form-control" id="exampleFormControlSelect1" v-model="question3" style="width:200px">
            <option value="1">Le css sert à personnaliser le design d'une page</option>
            <option value="2">Le css comporte des règles, des propriétes, des sélecteurs</option>
            <option value="3">Il existe 4 types de selecteur.</option>
            </select>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success3">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question3}}
    </div>
            <div class="question type_checkbox">
        <h4>Quelles sont les bonnes syntaxes d'écriture html ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="syntaxe1" v-model="question4">
        <label class="form-check-label" for="defaultCheck1">
           Syntaxe1  : &lt;img src="..." alt="..."/&gt;
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="syntaxe2" v-model="question4">
        <label class="form-check-label" for="defaultCheck2">
           Syntaxe2  : &lt;img href="..." title="..."/&gt;
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="syntaxe3" v-model="question4">
        <label class="form-check-label" for="defaultCheck3">
           Syntaxe3  : &lt;a link="..." title="..."/&gt;
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="syntaxe4" v-model="question4">
        <label class="form-check-label" for="defaultCheck4">
           Syntaxe4  : &lt;a href="..." title="..."/&gt;
        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success4">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
       Ta réponse :  {{ question4 }}
    </div>
        <div class="question type_radio">
        <h4>Que signifie CSS ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="Cassoulet Saucisses Salées" v-model="question5">
        <label class="form-check-label" for="exampleRadios1">
            Cassoulet Saucisses Salées
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="Cascading Style Sheets" v-model="question5">
        <label class="form-check-label" for="exampleRadios2">
            Cascading Style Sheets

        </label>
        </div>
        <div class="form-check disabled">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="Compacted Style Sheets" v-model="question5">
        <label class="form-check-label" for="exampleRadios3">
            Compacted Style Sheets

        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success5">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question5}}
    </div>

            <div class="question type_radio">
        <h4>Dans quoi définit-on les règles CSS ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="Entre deux accolades séparés par des points-virgules" v-model="question6">
        <label class="form-check-label" for="exampleRadios1">
            Entre deux accolades séparés par des points-virgules { ... ; ... }
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="Entre deux guillemets séparées par des slashs" v-model="question6">
        <label class="form-check-label" for="exampleRadios2">
            Entre deux guillemets séparées par des slashs " ... / ... "

        </label>
        </div>
        <div class="form-check disabled">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="Entre deux crochets sépareés par des tirets" v-model="question6">
        <label class="form-check-label" for="exampleRadios3">
            Entre deux crochets sépareés par des tirets [ ... - ... ]

        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success6">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question6}}
    </div>

                <div class="question type_checkbox">
        <h4>Dans la règle suivante, quel élément deviendra rouge ? .bgrouge {background-color: red;}</h4>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="option1" v-model="question7">
        <label class="form-check-label" for="defaultCheck1">
           Les éléments dont l'id est bgrouge
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="option2" v-model="question7">
        <label class="form-check-label" for="defaultCheck2">
           Les éléments dont la classe est bgrouge
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="option3" v-model="question7">
        <label class="form-check-label" for="defaultCheck3">
           L'élément écrit comme ceci en html &lt;p class="bgrouge"&gt;...&lt;/p&gt;
        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success7">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
       Ta réponse :  {{ question7 }}
    </div>

    <div class="question type_select">
        <h4>Comment écrire une liste ordrée ?</h4>
          <div class="form-group">
            <select class="form-control" id="exampleFormControlSelect1" v-model="question8" style="width:200px">
            <option value="1">&lt;ul&gt;&lt;li&gt;....&lt;/li&gt;&lt;/ul&gt;</option>
            <option value="2">&lt;ol&gt;&lt;li&gt;....&lt;/li&gt;&lt;/ol&gt;</option>
            <option value="3">Aucune réponse n'est valable.</option>
            </select>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success8">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question8}}
    </div>

            <br /> <br />
        <div class="action" v-if="!isOk">
            <button @click="verify" class="btn btn-primary">Vérifier mes réponses</button>
        </div>
        <div class="action" v-else>
            <button @click="redirect" class="btn btn-primary">Merci !, voir mon score</button>
        </div>
</div>
</template>
<script>
export default{
  data () {
    return {
      score:0,
      isOk : false,
      success1 : false,
      success2 : false,
      success3 : false,
      success4 : false,
      success5 : false,
      success6 : false,
      success7 : false,
      success8 : false,
      question1 : [],
      question2 : null,
      question3 : null,
      question4 : [],
      question5 : null,
      question6 : null,
      question7 : [],
      question8 : null,
      answers : [
          {reponse : ["Structurer du contenu"]},
          {reponse : '6 balises'},
          {reponse : "3"},
          {reponse : ["syntaxe1", "syntaxe4"]},
          {reponse : "Cascading Style Sheets"},
          {reponse : "Entre deux accolades séparés par des points-virgules"},
          {reponse : ["option2", "option3"]},
          {reponse : "2"},
      ]
    }
  },
  methods: {
    redirect () {
        this.$router.push({name : 'Home'})
    },
    verify () {
      if(this.question1.sort().toString() === this.answers[0].reponse.sort().toString()) {
         this.success1 = true
         this.score = (this.score + 5)
      } else {
          this.success1 = false
      }

     if(this.question2 === this.answers[1].reponse) {
         this.success2 = true
         this.score = (this.score + 5)
      } else {
          this.success2 = false
      }

      if(this.question3 === this.answers[2].reponse) {
         this.success3 = true
         this.score = (this.score + 5)
      } else {
          this.success3 = false
      }

    if(this.question4.sort().toString() === this.answers[3].reponse.sort().toString()) {
         this.success4 = true
         this.score = (this.score + 5)
      } else {
          this.success4 = false
      }

    if(this.question5 === this.answers[4].reponse) {
         this.success5 = true
         this.score = (this.score + 5)
    } else {
          this.success5 = false
    }

    if(this.question6 === this.answers[5].reponse) {
         this.success6 = true
         this.score = (this.score + 5)
    } else {
          this.success6 = false
    }

    if(this.question7.sort().toString() === this.answers[6].reponse.sort().toString()) {
         this.success7 = true
         this.score = (this.score + 5)
    } else {
          this.success7 = false
    }

    if(this.question8 === this.answers[7].reponse) {
         this.success8 = true
         this.score = (this.score + 5)
      } else {
          this.success8 = false
      }
    
    this.$store.commit('ajout_score', this.score)
    this.isOk = true
    }
  }

}
</script>

<style>

.question {
    margin: 0 0 40px 0;
}
</style>