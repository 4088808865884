import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Pb from '../views/PenseBete.vue'
import Form1 from '../views/Form1.vue'
import Nomprenom from '../views/Nomprenom.vue'
import Fouriertiago from '../views/Fouriertiago.vue'
import RigaudeauHugo from '../views/RigaudeauHugo.vue'
import melvin from '../views/melvin.vue'
import samsondiotmanuella from '../views/samsondiotmanuella.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/histoire-geo',
    name: 'histoire-geo',
    component: Fouriertiago
  },
  {
    path: '/mathematiques',
    name: 'mathematiques',
    component: melvin
  },
  {
    path: '/sciences-physiques',
    name: 'scp',
    component: RigaudeauHugo
  },
  {
    path: '/anglais',
    name: 'anglais',
    component: samsondiotmanuella
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/formulaire1',
    name: 'Quizz Html / Css',
    component: Form1
  },
  {
    path: '/nomprenom',
    name: 'Correction',
    component: Nomprenom
  },
  {
    path: '/pense-bete',
    name: 'Pense Bête',
    component: Pb
  }
  /*{
    path: '/formulaire2',
    name: 'Formulaire 2',
    component: Form2
  },
  {
    path: '/formulaire3',
    name: 'Formulaire 3',
    component: Form3
  },
  {
    path: '/formulaire4',
    name: 'Formulaire 4',
    component: Form4
  },
  {
    path: '/formulaire5',
    name: 'Formulaire 5',
    component: Form5
  }*/
]

const router = new VueRouter({
  routes
})

export default router
