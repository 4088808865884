 <template>
<div class="formulaire1 text-left">
    <div class="card-body">
      <div class="header2">
        <h2>                  Quiz sur l'Histoire Géographie.                      </h2>
      </div>
      <router-view/>
    </div>
    <h2>Bienvenue sur le quiz de Géographie</h2>
    <div class="question type_checkbox">
        <h4>{{this.$store.state.prenom}}Quels continents sont concernés par le commerce triangulaire ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="L’Amérique, l’Asie et l’Europe" v-model="question1">
        <label class="form-check-label" for="defaultCheck1">
           L’Amérique, l’Asie et l’Europe
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="L’Europe, l’Afrique et l’Amérique" v-model="question1">
        <label class="form-check-label" for="defaultCheck2">
            L’Europe, l’Afrique et l’Amérique
        </label>
        </div>
                <div class="form-check">
        <input class="form-check-input" type="checkbox" value="L’Europe, l’Asie et l’Amérique" v-model="question1">
        <label class="form-check-label" for="defaultCheck1">
            L’Europe, l’Asie et l’Amérique
        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success1">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
         oooh mince!! recommence
            </div>
        </div>
       Ta réponse :  {{ question1 }}
    </div>
    <div class="question type_radio">
        <h4>Quelle produits utilisent les Européens pour acheter des esclaves noirs ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="Des armes, des pacotilles et de l’alcool" v-model="question2">
        <label class="form-check-label" for="exampleRadios1">
            Des armes, des pacotilles et de l’alcool
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value=" Des épices, des produits tropicaux et du thé" v-model="question2">
        <label class="form-check-label" for="exampleRadios2">
            Des épices, des produits tropicaux et du thé

        </label>
        </div>
        <div class="form-check disabled">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="De l’or, des tomates et de la soie" v-model="question2">
        <label class="form-check-label" for="exampleRadios3">
            De l’or, des tomates et de la soie

        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success2">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                   oooh mince!! recommence
            </div>
        </div>
        Ta réponse : {{ question2}}
    </div>
        <div class="question type_select">
        <h4>Qu’est-ce qu’une mégapole ?</h4>
          <div class="form-group">
            <select class="form-control" id="exampleFormControlSelect1"  style="width:200px" v-model="question3">
            <option value="1">Une ville de plus de 10 000 habitants</option>
            <option value="2">Une ville de plus de 10 millions d’habitants</option>
            <option value="3">Une ville de plus de 10 milliards d’habitants</option>
            </select>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success3">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    oooh mince!! recommence
            </div>
        </div>
        Ta réponse : {{question3}}
    </div>
            <div class="question type_checkbox">
        <h4>Que signifie « Downtown » en géographie ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="C’est le centre-ville d’une ville américaine" v-model="question4">
        <label class="form-check-label" for="defaultCheck1">
           C’est le centre-ville d’une ville américaine
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value=" C’est la ville basse d’une cité médiévale" v-model="question4">
        <label class="form-check-label" for="defaultCheck2">
           C’est la ville basse d’une cité médiévale
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="C’est le métro dans une grande ville" v-model="question4">
        <label class="form-check-label" for="defaultCheck3">
           C’est le métro dans une grande ville
        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success4">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    oooh mince!! recommence
            </div>
        </div>
       Ta réponse :  {{ question4 }}
    </div>
        <div class="question type_radio">
        <h4>Qu’est ce que « Les Lumières »?</h4>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="Une courant électrique du XVIIIe siècle." v-model="question5">
        <label class="form-check-label" for="exampleRadios1">
            Une courant électrique du XVIIIe siècle.
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="Un courant philosophique du XVIIIe siècle," v-model="question5">
        <label class="form-check-label" for="exampleRadios2">
            Un courant philosophique du XVIIIe siècle,

        </label>
        </div>
        <div class="form-check disabled">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="Un courant philosophique du XVIIe siècle" v-model="question5">
        <label class="form-check-label" for="exampleRadios3">
            Un courant philosophique du XVIIe siècle

        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success5">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    oooh mince!! recommence
            </div>
        </div>
        Ta réponse : {{question5}}
    </div>

            <div class="question type_radio">
        <h4>La prise de la Bastille, grand évènement de la Révolution française a lieu :</h4>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="Le 14 juillet 1787" v-model="question6">
        <label class="form-check-label" for="exampleRadios1">
            Le 14 juillet 1787
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="Le 14 juillet 1789" v-model="question6">
        <label class="form-check-label" for="exampleRadios2">
            Le 14 juillet 1789

        </label>
        </div>
        <div class="form-check disabled">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="Le 14 juin 1789" v-model="question6">
        <label class="form-check-label" for="exampleRadios3">
            Le 14 juin 1789

        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success6">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                  oooh mince!! recommence
            </div>
        </div>
        Ta réponse : {{question6}}
    </div>

                <div class="question type_checkbox">
        <h4>Quel roi de France est exécuté le 21 janvier 1793 ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="Louis XIV" v-model="question7">
        <label class="form-check-label" for="defaultCheck1">
           Louis XIV
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="Louis XV" v-model="question7">
        <label class="form-check-label" for="defaultCheck2">
           Louis XV
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="checkbox" value="Louis XVI" v-model="question7">
        <label class="form-check-label" for="defaultCheck3">
         Louis XVI
        </label>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success7">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    oooh mince!! recommence
            </div>
        </div>
       Ta réponse :  {{ question7 }}
    </div>

    <div class="question type_select">
        <h4>Quel est le plus grand port de commerce européen ?</h4>
          <div class="form-group">
            <select class="form-control" id="exampleFormControlSelect1" v-model="question8" style="width:200px">
            <option value="1">Nantes</option>
            <option value="2">Le Havre</option>
            <option value="3">Rotterdam</option>
            <option value="4">Aucune réponse n'est valable.</option>
            </select>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success8">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    oooh mince!! recommence
            </div>
        </div>
        Ta réponse : {{question8}}
    </div>
     <div class="question type_select">
        <h4>Napoléon Ier est sacré le :</h4>
          <div class="form-group">
            <select class="form-control" id="exampleFormControlSelect1" v-model="question9" style="width:200px">
            <option value="1">2 décembre 1802</option>
            <option value="2">2 décembre 1804</option>
            <option value="3">2 décembre 1806</option>
            <option value="4">Aucune réponse n'est valable.</option>
            </select>
        </div>
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success9">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    oooh mince!! recommence
            </div>
        </div>
        Ta réponse : {{question9}}
    </div>

            <br /> <br />
        <div class="action" v-if="!isOk">
            <button @click="verify" class="btn btn-primary">Vérifier mes réponses</button>
        </div>
        <div class="action" v-else>
            <button @click="redirect" class="btn btn-primary">Merci !, voir mon score</button>
        </div>
</div>
</template>
<script>
export default{
  data () {
    return {
      score:0,
      isOk : false,
      success1 : false,
      success2 : false,
      success3 : false,
      success4 : false,
      success5 : false,
      success6 : false,
      success7 : false,
      success8 : false,
      question1 : [],
      question2 : null,
      question3:null,
      question4 : [],
      question5 : null,
      question6 : null,
      question7 : [],
      question8 : null,
      answers : [
          {reponse : ["L’Europe, l’Afrique et l’Amérique"]},
          {reponse : 'Des épices, des produits tropicaux et du thé'},
          {reponse : "Une ville de plus de 10 millions d’habitants"},
          {reponse : ["C’est le centre-ville d’une ville américaine"]},
          {reponse : "Un courant philosophique du XVIIIe siècle,"},
          {reponse : "Le 14 juillet 1789"},
          {reponse : ["Louis XVI"]},
          {reponse : "3"},
          {reponse : "2"},
      ]
    }
  },
  methods: {
    redirect () {
        this.$router.push({name : 'Home'})
    },
    verify () {
      if(this.question1.sort().toString() === this.answers[0].reponse.sort().toString()) {
         this.success1 = true
         this.score = (this.score + 5)
      } else {
          this.success1 = false
      }

     if(this.question2 === this.answers[1].reponse) {
         this.success2 = true
         this.score = (this.score + 5)
      } else {
          this.success2 = false
      }

      if(this.question3 === this.answers[2].reponse) {
         this.success3 = true
         this.score = (this.score + 5)
      } else {
          this.success3 = false
      }

    if(this.question4.sort().toString() === this.answers[3].reponse.sort().toString()) {
         this.success4 = true
         this.score = (this.score + 5)
      } else {
          this.success4 = false
      }

    if(this.question5 === this.answers[4].reponse) {
         this.success5 = true
         this.score = (this.score + 5)
    } else {
          this.success5 = false
    }

    if(this.question6 === this.answers[5].reponse) {
         this.success6 = true
         this.score = (this.score + 5)
    } else {
          this.success6 = false
    }

    if(this.question7.sort().toString() === this.answers[6].reponse.sort().toString()) {
         this.success7 = true
         this.score = (this.score + 5)
    } else {
          this.success7 = false
    }

    if(this.question8 === this.answers[7].reponse) {
         this.success8 = true
         this.score = (this.score + 5)
      } else {
          this.success8 = false
      }
      if(this.question9 === this.answers[8].reponse) {
         this.success9 = true
         this.score = (this.score + 5)
      } else {
          this.success9 = false
      }
    
    this.$store.commit('ajout_score', this.score)
    this.isOk = true
    }
  }

}
</script>

<style>

.question {
    margin: 0 0 40px 0;
}

.header2{
    color:rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    
}

.header2 h2{
    font-size: 70px !important;
}
</style>