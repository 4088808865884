<template>
<div class="intro">
    <div>
        <form>
  <div class="form-group">
    <label for="exampleInputEmail1">Entrez votre prénom</label>
    <input type="text" class="form-control" id="name" placeholder="Entrez votre prénom" v-model="name" @blur="sav">
  </div>

  <div v-if="name=='intéractivité'" class="interactivite alert alert-success">
      L'intéractivité en web regroupe l'ensemble des éléments permettant de lier les actions de l'utilsateur aux réactions de l'interface.
      <br />
      Par exemple ici, l'utilisateur en tapant le mot "intéractivité", a fait réagir l'interface qui enretour à afficher d'elle même la définition du mot.
  </div>

  <h3>Bienvenue {{name}}</h3>
      <p>
        Tu peux tester tes connaissances en cliquant sur le lien ci-dessous !
    </p>
</form>
    </div>
    <div v-if="this.$store.state.score">
        Votre score : {{this.$store.state.score}}
    </div>
</div>
</template>

<script>
export default{
  data () {
    return {
      name : this.$store.state.prenom,
    }
  },
  methods: {
      sav () {
          this.$store.commit('sauv_prenom', this.name)
      }
  }
}
</script>
<style>
.interactivite {
  padding: 20px;
}
</style>