import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    prenom : 'Guest',
    score : 0
  },
  mutations: {
    sauv_prenom (state, item) {
      state.prenom = item
    },
    ajout_score (state, item) {
      state.score = parseInt(state.score) + parseInt(item)
    }
  },
  actions: {
  },
  modules: {
  }
})
