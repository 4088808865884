<template>
<div class="formulaire1 text-left">

    <div class="question type_radio">
        <h4>Qu'est-ce qu'un combustible ?</h4>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="Un élément qui brule" v-model="question1">
        <label class="form-check-label" for="exampleRadios1">
            Un élément qui brule 
        </label>
        </div>
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="Un élément qui permet la combustion" v-model="question1">
        <label class="form-check-label" for="exampleRadios2">
                       Un élément qui permet la combustion

        </label>
        </div>

        <div v-if="isOk">
            <div class="alert alert-success" v-if="success1">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question1}}
    </div>
        <div class="question type_radio">
        <h4>Qu’est-ce qu’un comburant ?</h4>

                    <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios2" value="Ce qui permet la combustion" v-model="question2">
        <label class="form-check-label" for="exampleRadios2">
             Ce qui permet la combustion.
        </label>
        </div>
                <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios3" value="Un élément qui brule" v-model="question2">
        <label class="form-check-label" for="exampleRadios1">
             Un élément qui brule
        </label>
        </div>
                    <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios3" value="C’est la même chose qu’un carburant " v-model="question2">
        <label class="form-check-label" for="exampleRadios2">
                 C’est la même chose qu’un carburant        </label>
        </div>
       
       
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success2">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
        Ta réponse : {{question2}}
    </div>
            <div class="question type_radio">
        <h4>A ton avis, combien faut-il d’éléments pour réaliser une combustion ?</h4>
                    <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios4" id="exampleRadios3" value="2" v-model="question3">
        <label class="form-check-label" for="exampleRadios2">
                 2        
        </label>
        </div>
                      <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios4" id="exampleRadios3" value="3" v-model="question3">
        <label class="form-check-label" for="exampleRadios2">
                 3      
        </label>
        </div>
        
     
        <div v-if="isOk">
            <div class="alert alert-success" v-if="success3">
                    Bravo tu as trouvé la bonne réponse
            </div>
            <div class="alert alert-warning" v-else>
                    Dommage essaie encore
            </div>
        </div>
       Ta réponse :  {{ question3 }}
    </div>
            <br /> <br />
        <div class="action" v-if="!isOk">
            <button @click="verify" class="btn btn-primary">Vérifier mes réponses</button>
        </div>
        <div class="action" v-else>
            <button @click="redirect" class="btn btn-primary">Merci !, voir mon score</button>
        </div>
</div>
</template>
<script>
export default{
  data () {
    return {
      score:0,
      isOk : false,
      success1 : false,
      success2 : false,
      success3 : false,
      success4 : false,
      success5 : false,
      success6 : false,
      success7 : false,
      success8 : false,
      success9 : false,
      question1 : null,
      question2 : null,
      question3: null,
      question4 : [],
      question5 : null,
      question6 : null,
      question7 : [],
      question8 : null,
      question9 : [],
      answers : [
          {reponse : "Un élément qui brule"},
          {reponse : 'Ce qui permet la combustion'},
          {reponse : "3"},
          {reponse : ["syntaxe1", "syntaxe4"]},
          {reponse : "Cascading Style Sheets"},
          {reponse : "Entre deux accolades séparés par des points-virgules"},
          {reponse : ["option2", "option3"]},
          {reponse : "2"},
          {reponse : ["option1"]},
      ]
    }
  },
  methods: {
    redirect () {
        this.$router.push({name : 'Home'})
    },
    verify () {
       if(this.question1 === this.answers[0].reponse) {
         this.success1 = true
         this.score = (this.score + 5)
      } else {
          this.success1 = false
      }

     if(this.question2 === this.answers[1].reponse) {
         this.success2 = true
         this.score = (this.score + 5)
      } else {
          this.success2 = false
      }

      if(this.question3 === this.answers[2].reponse) {
         this.success3 = true
         this.score = (this.score + 5)
      } else {
          this.success3 = false
      }

    if(this.question4.sort().toString() === this.answers[3].reponse.sort().toString()) {
         this.success4 = true
         this.score = (this.score + 5)
      } else {
          this.success4 = false
      }

    if(this.question5 === this.answers[4].reponse) {
         this.success5 = true
         this.score = (this.score + 5)
    } else {
          this.success5 = false
    }

    if(this.question6 === this.answers[5].reponse) {
         this.success6 = true
         this.score = (this.score + 5)
    } else {
          this.success6 = false
    }

    if(this.question7.sort().toString() === this.answers[6].reponse.sort().toString()) {
         this.success7 = true
         this.score = (this.score + 5)
    } else {
          this.success7 = false
    }

    if(this.question8 === this.answers[7].reponse) {
         this.success8 = true
         this.score = (this.score + 5)
      } else {
          this.success8 = false
      }

      if(this.question9.sort().toString()  === this.answers[8].reponse.sort().toString()) {
          this.success9 = true
          this.score = (this.score + 5)
      } else {
          this.success9 = false
      }
    
    this.$store.commit('ajout_score', this.score)
    this.isOk = true
    }
  }

}
</script>

<style>

.question {
    margin: 0 0 40px 0;
}
</style>